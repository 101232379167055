<template>
    <a class="product-item" :href="'/selling-field/chi-tiet/'+prod.id">
        <div class="row g-5 align-items-center">
            <div class="container-fluid col-3 p-0 pe-2 wow fadeIn" data-wow-delay="0.1s" style="border-right: 2px solid green;">
                <img v-if="prod.feature_image != null" class="w-100" :src="host_url + prod.feature_image" alt="Image">
                <img v-else class="w-100" src="/logo.jpg" alt="Image"/>
            </div>
            <div class="col-9 wow fadeIn" data-wow-delay="0.5s">
                <span v-if="prod.selling_price > 0" class="text-danger me-1 text-responsive">{{ $filters.capitalize($t('product.selling-price')) }}: {{ prod.selling_price.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) }}</span>
                <span v-if="prod.deposit > 0" class="text-danger me-1 text-responsive">{{ $filters.capitalize($t('selling-field.deposit')) }}: {{ prod.deposit.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) }}</span>
                <br />
                <span class="text-body">{{ prod.fm_field.name }}</span>
                <br />
                <div class="text-muted border-top pt-0" v-if="prod.created_at != null">
                    <small class="me-3 text-responsive">{{ (new Date(prod.created_at)).toLocaleString() }}</small>
                </div>
            </div>
        </div>
    </a>
</template>
<script>
import { api_backend } from '@/constants/configs';
export default {
    name: "ProductItem",
    data(){
        return {
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend
        }
    },
    props: [
        'prod'
    ]
}
</script>