<template>
    <div class="container-xxl py-3" style="min-height: 140px;">
        <div class="container" style="min-height: 140px;">
            <div class="mshopes-header-section pb-3 border-bottom border-success border-3">
                <div class="title text-center text-success">
                    {{ $filters.capitalize($t('title.price-quotation')) }}
                </div>
            </div>
            <div class="row g-2 mt-3">
                <div v-for="(prod, index) in price_quotations" class="col-xl-4 col-lg-4 col-md-12 col-xs-12 col-sm-12 wow fadeInUp" data-wow-delay="0.1s" v-bind:key="index">
                    <a class="product-item" :href="'/price-quotation/chi-tiet/'+prod.id">
                        <div class="row g-5 align-items-center">
                            <div class="container-fluid col-3 p-0 pe-2 wow fadeIn" data-wow-delay="0.1s" style="border-right: 2px solid red;">
                                <img v-if="prod.fm_cultivar.feature_image != null" class="w-100" :src="host_url + prod.fm_cultivar.feature_image" alt="Image">
                                <img v-else class="w-100" src="/logo.jpg" alt="Image"/>
                            </div>
                            <div class="col-9 wow fadeIn" data-wow-delay="0.5s">
                                <span v-if="prod.from_price > 0" class="text-danger me-1 text-responsive">{{ prod.from_price.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) }} - {{ prod.to_price.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) }}</span>
                                <br />
                                <span class="text-body">{{ prod.name }} - {{ prod.shop.name }} - {{ prod.shop.address }}</span>
                                <br />
                                <div class="text-muted border-top pt-0" v-if="prod.datatime != null">
                                    <small class="me-3 text-responsive">{{ (new Date(prod.datatime*1000)).toLocaleString() }}</small>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                    <a class="btn btn-default btn-sm py-2 px-3 text-success border" href="/price-quotation">{{ $filters.capitalize($t('action.more')) }}</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { api_backend } from '@/constants/configs';
export default {
    name: "PriceQuotation",
    data(){
        return {
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend
        };
    },
    components: {
        
    },
    created() {
    },
    methods: {
    },
    props: ['price_quotations']
}
</script>
<style>
.mshopes-header-section .title {
    text-transform: uppercase;
}
.mshopes-header-section-content .title {
    text-transform: uppercase;
}
@media screen and (max-width: 768px) {
    
}
</style>