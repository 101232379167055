<template>
    <navbar-index :change-keywords="changeKeyword" :click-search="onSearch" />
    <!-- Page Header Start -->
    <div class="container-fluid my-3 wow fadeIn" data-wow-delay="0.1s">
        <div class="container">
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ $filters.capitalize($t('title.price-quotation')) }}</li>
                </ol>
            </nav>
        </div>
    </div>
    <!-- Page Header End -->
    <form-sort :change-sort="changeSort" />
    <!-- Product Start -->
    <div class="container-xxl py-3">
        <div class="container p-0">
            <div class="row g-2">
                <div class="col-2 col-sidebarmenu">
                    <filter-form :is-category="false" :change-from-price="changeFromPrice" :change-to-price="changeToPrice" :change-location="changeLocation" :change-shop="changeShop" :change-ship-method="changeShipMethod" />
                </div>
                <div class="col-10 col-result">
                    <div class="row g-2">
                        <div v-for="(prod, index) in products" class="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-sm-12 wow fadeInUp" data-wow-delay="0.1s" v-bind:key="index">
                            <product-item :prod="prod" />
                        </div>
                        <pagination-index :page="search.page" :total="search.total" :page-size="search.pagesize" :change-page="changePage" :next-page="nextPage" :prev-page="prevPage" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Product End -->
</template>
<script>
import PaginationIndex from '../components/Pagination.vue'
import FormSort from './components/FormSort.vue'
import FilterForm from './components/FilterForm.vue'
import {list} from '@/api/fm_price_quotation'
import { api_backend } from '@/constants/configs';
import NavbarIndex from '@/components/NavbarIndex.vue'
import ProductItem from './components/ProductItem.vue'
export default {
    name: "ProductIndex",
    data() {
        return {
            category: null,
            isShowFilter: false,
            products: [],
            search: { 
                page: 1,
                pagesize: 30,
                keywords: '',
                total: 0,
                sort: [],
                shop_id: 0,
                ward_code: null,
                ship_id: 0,
                from_price: 0,
                to_price: 0
            },
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend
        };
    },
    components: {
        PaginationIndex,
        FormSort,
        NavbarIndex,
        FilterForm,
        ProductItem
    },
    async created(){
        await this.getProducts();
    },
    methods: {
        async getProducts(){
            let query = 'page='+this.search.page+'&pagesize='+this.search.pagesize;
            if (this.search.keywords.trim() != '') {
                query += '&keywords='+this.search.keywords.trim()
            }
            if (this.search.from_price > 0) {
                query += '&from_price='+this.search.from_price;
            }
            if (this.search.to_price > 0) {
                query += '&to_price='+this.search.to_price;
            }
            if (this.search.ward_code != null) {
                query += '&ward_code='+this.search.ward_code;
            }
            if (this.search.ship_id > 0) {
                query += '&ship_id='+this.search.ship_id;
            }
            if (this.search.sort.length > 0) {
                for(let i = 0; i < this.search.sort.length; i++) {
                    let arr = this.search.sort[i].split('-');
                    if (arr.length >= 2) {
                        query += '&sort['+arr[0]+']='+arr[1];
                    }
                }
            }
            let res = await list(query);
            if (res != null && res.status == 200 && res.data != null ){
                this.search.page = res.data.page;
                this.search.pagesize = res.data.pagesize;
                this.search.total = res.data.total;
                this.products = res.data.data;
            }
        },
        changePage(value){
        if (this.search.page != value) {
            this.search.page = value;
            this.$router.push({ path: '/price-quotation', query: this.search });
        }
        },
        nextPage(){
            this.search.page = Number(this.search.page)+1;
            this.$router.push({ path: '/price-quotation', query: this.search });
        },

        prevPage(){
            this.search.page = Number(this.search.page)-1;
            this.$router.push({ path: '/price-quotation', query: this.search });
        },

        changeKeyword(e) {
            this.search.keywords = e.target.value;
        },
        async onSearch(){
            this.$router.push({ path: '/price-quotation', query: { keywords: this.search.keywords, page: this.search.page, pagesize: this.search.pagesize, sort: this.search.sort, shop_id: this.search.shop_id, ward_code: this.search.ward_code, ship_id: this.search.ship_id, from_price: this.search.from_price, to_price: this.search.to_price } });
        },
        onRefresh(){
            this.search = { 
                page: 1,
                pagesize: 30,
                keywords: '',
                total: 0,
                sort: [],
                shop_id: 0,
                ward_code: null,
                ship_id: 0,
                from_price: 0,
                to_price: 0
            };
            this.$router.push({ path: '/price-quotation'});

        },
        changeSort(e){
            this.search.sort = [];
            this.search.sort.push(e.target.value);
            this.$router.push({ path: '/price-quotation', query: { keywords: this.search.keywords, page: this.search.page, pagesize: this.search.pagesize, sort: this.search.sort, shop_id: this.search.shop_id, ward_code: this.search.ward_code, ship_id: this.search.ship_id, from_price: this.search.from_price, to_price: this.search.to_price } });
        },
        changeShop(e){
            this.search.shop_id = e.target.value;
        },
        changeLocation(e){
            this.search.ward_code = e.target.value;
        },
        changeShipMethod(e){
            this.search.ship_id = e.target.value;
        },
        changeFromPrice(e) {
            this.search.from_price = e.target.value;
        },
        changeToPrice(e) {
            this.search.to_price = e.target.value;
        },
    },
    watch: {
        '$route'(to, from){
            console.log(to);
            console.log(from);
            this.getProducts();
        }
    },
}
</script>
<style>
.about-img {
    max-width: 300px;
}
</style>