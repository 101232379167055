<template>
    
    <!-- Page Header Start -->
    <navbar-index :change-keywords="changeKeyword" :click-search="onSearch" />
    <div class="container-fluid my-3 wow fadeIn" data-wow-delay="0.1s">
        <div class="container">
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item"><a class="text-body" href="/price-quotation">{{ $filters.capitalize($t('title.price-quotation')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ ProductDetail.name +'-'+ ProductDetail.shop.name }}</li>
                </ol>
            </nav>
        </div>
    </div>
    <!-- About Start -->
    <div class="container-xxl py-5">
        <div class="container">
            <div class="row g-5 align-items-center">
                <div class="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-4">{{ ProductDetail.name }}</h1>
                    <img v-if="ProductDetail.shop.feature_image != null" class="img-fluid" :src="host_url + ProductDetail.shop.feature_image" alt="No image" >
                    <div class="mb-1">
                        <span class="display-7 mb-3 me-2">{{ $filters.capitalize($t('price-quotation.from-price')) }}: </span>
                        <span v-if="ProductDetail.from_price != null && ProductDetail.from_price > 0" class="text-primary me-1">{{ ProductDetail.from_price.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) }}</span>
                    </div>
                    <div class="mb-1">
                        <span class="display-7 mb-3 me-2">{{ $filters.capitalize($t('price-quotation.to-price')) }}: </span>
                        <span v-if="ProductDetail.to_price != null && ProductDetail.to_price > 0" class="text-primary me-1">{{ ProductDetail.to_price.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) }}</span>
                    </div>
                    <div class="mb-1">
                        <span class="display-7 mb-3 me-2">{{ $filters.capitalize($t('price-quotation.from-quantity')) }}: </span>
                        <span v-if="ProductDetail.from_quantity != null && ProductDetail.from_quantity > 0" class="text-primary me-1">{{ ProductDetail.from_quantity }}</span>
                    </div>
                    <div class="mb-1">
                        <span class="display-7 mb-3 me-2">{{ $filters.capitalize($t('price-quotation.to-quantity')) }}: </span>
                        <span v-if="ProductDetail.to_quantity != null && ProductDetail.to_quantity > 0" class="text-primary me-1">{{ ProductDetail.to_quantity }}</span>
                    </div>
                    <div class="mb-1" v-if="ProductDetail.shop != null">
                        <span class="display-7 mb-3 me-2">{{ $filters.capitalize($t('title.shop')) }}: </span>
                        <span v-if="ProductDetail.shop.name != null" class="text-primary me-1">{{ ProductDetail.shop.name }}</span>
                    </div>
                    <div class="mb-1" v-if="ProductDetail.shop != null">
                        <span class="display-7 mb-3 me-2">{{ $filters.capitalize($t('title.address')) }}: </span>
                        <span v-if="ProductDetail.shop.address != null" class="text-primary me-1">{{ ProductDetail.shop.address }}</span>
                    </div>
                    <div class="mb-1" v-if="ProductDetail.datatime != null">
                        <span class="display-7 mb-3 me-2">{{ $filters.capitalize($t('price-quotation.datatime')) }}: </span>
                        <span v-if="ProductDetail.datatime != null" class="text-primary me-1">{{ (new Date(ProductDetail.datatime*1000)).toLocaleString() }}</span>
                    </div>
                    <div class="row">
                        <div class="form-group mb-2 col col-md-12">
                            <span class="display-7 mb-3 me-2">{{ $filters.capitalize($t('action.share')) }}: </span>
                            <a :href="'https://www.facebook.com/sharer/sharer.php?u=http://nongsanshop.com/price-quotation/chi-tiet/'+ProductDetail.id" type="button" class="btn btn-success me-2 btn-sm" target="_blank"><i class="fab fa-facebook"></i></a>
                            <a :href="'https://twitter.com/intent/tweet?text='+ProductDetail.name+'-'+ProductDetail.shop.name+'&url=http://nongsanshop.com/price-quotation/chi-tiet/'+ProductDetail.id+'&hashtags='+ProductDetail.name + '-'+ProductDetail.shop.name" type="button" class="btn btn-info me-2 btn-sm" target="_blank"><i class="fab fa-twitter"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row g-0 gx-5 align-items-end">
                <div class="col-lg-6">
                    <div class="section-header text-start mb-5 wow fadeInUp" data-wow-delay="0.1s" style="max-width: 500px;">
                        <h4 class="display-7 mb-3">{{ $filters.capitalize($t('price-quotation.related-price-quotations')) }}</h4>
                    </div>
                </div>
            </div>
            <div class="row g-4">
                <div v-for="(prod, index) in products" class="col-xl-4 col-lg-4 col-md-6 col-xs-12 col-sm-12 wow fadeInUp" data-wow-delay="0.1s" v-bind:key="index">
                    <product-item :prod="prod" />
                </div>
                <div class="col-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                    <a class="btn btn-primary rounded-pill py-2 px-3 btn-sm" href="/price-quotation">{{ $filters.capitalize($t('action.more-fields')) }}</a>
                </div>
            </div>
        </div>
    </div>
    <!-- About End -->
</template>
<script>
import { detail, list } from '@/api/fm_price_quotation';
import { api_backend } from '@/constants/configs';
import ProductItem from './components/ProductItem.vue'
// import BuyerFeatures from '../components/BuyerFeatures.vue'
import NavbarIndex from '@/components/NavbarIndex.vue'
export default {
    name: "ProductDetail",
    components: {
        ProductItem,
        NavbarIndex
    },
    data() {
        return {
            ProductDetail: {
                from_price: 0,
                to_price: 0,
                cultivar_id: 0,
                shop_id: 0,
                from_quantity: 0,
                to_quantity: 0,
                shop: {
                    name: '',
                    feature_image: null
                },
                cultivar: {},
                ward: {}
            },
            products: [],
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend,
            keywords: ''
        }
    },
    async created(){
        let res = await detail(this.$route.params.id);
        if (res != null && res.status == 200 && res.data != null ){
            this.ProductDetail = res.data;
            document.title = this.ProductDetail.name + "-" + this.ProductDetail.shop.name;
            let content = this.ProductDetail.name + "-" + this.ProductDetail.shop.name;
            const descEl = document.querySelector('head meta[name="description"]');
            descEl.setAttribute('content', content);
            const keywordscEl = document.querySelector('head meta[name="keywords"]');
            keywordscEl.setAttribute('content', content);
            // set facebook feature
            const titleElFacebook = document.querySelector('head meta[property="og:title"]');
            if (titleElFacebook != null) {
                titleElFacebook.setAttribute('content', content);
            }
            const descElFacebook = document.querySelector('head meta[property="og:description"]');
            if (descElFacebook != null) {
                descElFacebook.setAttribute('content', content);
            }
            const urlElFacebook = document.querySelector('head meta[property="og:url"]');
            if (urlElFacebook != null) {
                urlElFacebook.setAttribute('content', this.host_url+"price-quotation/chi-tiet/"+this.ProductDetail.id);
            }
            const imageElFacebook = document.querySelector('head meta[property="og:image"]');
            if (imageElFacebook != null && this.ProductDetail.shop.feature_image != null) {
                imageElFacebook.setAttribute('content', this.host_url + this.ProductDetail.shop.feature_image.replace('public', this.backend+'/storage'));
            }
            // set twitter feature
            const titleElTwitter= document.querySelector('head meta[name="twitter:title"]');
            if (titleElTwitter != null) {
                titleElTwitter.setAttribute('content', content);
            }
            const descElTwitter = document.querySelector('head meta[name="twitter:description"]');
            if (descElTwitter != null) {
                descElTwitter.setAttribute('content', content);
            }
            const imageElTwitter = document.querySelector('head meta[name="twitter:image"]');
            if (imageElTwitter != null && this.ProductDetail.shop.feature_image != null) {
                imageElTwitter.setAttribute('content', this.host_url + this.ProductDetail.shop.feature_image);
            }
            let resRelated = await list('page=1&pagesize=6&cultivar_id='+this.ProductDetail.cultivar_id);
            if (resRelated != null && resRelated.status == 200 && resRelated.data != null && resRelated.data.data != null && resRelated.data.data.length > 0 ){
                this.products = resRelated.data.data;
            }
        }
    },
    methods: {
        changeKeyword(e){
            this.keywords = e.target.value;
        },
        onSearch(){
            this.$router.push({ path: '/price-quotation', query: { keywords: this.keywords } });
        }
    }
}
</script>