<template>
    
    <!-- Page Header Start -->
    <navbar-index :change-keywords="changeKeyword" :click-search="onSearch" />
    <div class="container-fluid my-3 wow fadeIn" data-wow-delay="0.1s">
        <div class="container">
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item"><a class="text-body" href="/selling-field">{{ $filters.capitalize($t('title.selling-field')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ ProductDetail.fm_field.name }}</li>
                </ol>
            </nav>
        </div>
    </div>
    <!-- About Start -->
    <div class="container-xxl py-5">
        <div class="container">
            <div class="row g-5 align-items-center">
                <div class="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-4">{{ ProductDetail.name }}</h1>
                    <img v-if="ProductDetail.feature_image != null" class="img-fluid" :src="host_url + ProductDetail.feature_image" alt="No image" >
                    <p v-for="(prod, index) in ProductDetail.features" v-bind:key="index"><i class="fa fa-check text-primary me-3"></i>{{ prod }}</p>
                    <div v-if="ProductDetail.prod_product_categories != null" class="d-flex pt-2 mb-4">
                        <small v-for="(prod, index) in ProductDetail.prod_product_categories" class="text-center border-end px-2" v-bind:key="index">
                            <a class="btn btn-primary rounded-pill btn-sm" :href="'/san-pham/'+prod.product_category.slug">{{ prod.product_category.name }}</a>
                        </small>
                    </div>
                    <div class="mb-1">
                        <span class="display-7 mb-3 me-2">{{ $filters.capitalize($t('product.price')) }}: </span>
                        <span v-if="ProductDetail.selling_price != null && ProductDetail.selling_price > 0" class="text-primary me-1">{{ ProductDetail.selling_price.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) }}</span>
                        <span v-else class="text-danger me-1 text-responsive">{{ $filters.capitalize($t('title.contact')) }}</span>
                    </div>
                    <div class="mb-1">
                        <span class="display-7 mb-3 me-2">{{ $filters.capitalize($t('selling-field.deposit')) }}: </span>
                        <span v-if="ProductDetail.deposit != null && ProductDetail.deposit > 0" class="text-primary me-1">{{ ProductDetail.deposit.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) }}</span>
                    </div>
                    <div class="mb-1" v-if="ProductDetail.fm_field != null">
                        <span class="display-7 mb-3 me-2">{{ $filters.capitalize($t('selling-field.area')) }}: </span>
                        <span v-if="ProductDetail.fm_field != null && ProductDetail.fm_field.width > 0 && ProductDetail.fm_field.length > 0" class="text-primary me-1">{{ ProductDetail.fm_field.length }} X {{ ProductDetail.fm_field.width }} (m2)</span>
                    </div>
                    <div class="mb-1" v-if="ProductDetail.fm_field != null">
                        <span class="display-7 mb-3 me-2">{{ $filters.capitalize($t('selling-field.crop-quantity')) }}: </span>
                        <span v-if="ProductDetail.fm_field != null && ProductDetail.fm_field.width > 0 && ProductDetail.fm_field.length > 0" class="text-primary me-1">{{ ProductDetail.fm_field.length }} X {{ ProductDetail.fm_field.width }} (m2)</span>
                    </div>
                    <div class="mb-1" v-if="ProductDetail.fm_field != null">
                        <span class="display-7 mb-3 me-2">{{ $filters.capitalize($t('selling-field.predict-capacity')) }}: </span>
                        <span v-if="ProductDetail.fm_field.predict_capacity != null" class="text-primary me-1">{{ ProductDetail.fm_field.predict_capacity }} {{ $filters.capitalize($t('title.kilogam')) }}</span>
                    </div>
                    <div class="mb-1" v-if="ProductDetail.fm_field != null">
                        <span class="display-7 mb-3 me-2">{{ $filters.capitalize($t('selling-field.predict-harvest-time')) }}: </span>
                        <span v-if="ProductDetail.fm_field.predict_harvest_time != null" class="text-primary me-1">{{ (new Date(ProductDetail.fm_field.predict_harvest_time)).toLocaleString() }}</span>
                    </div>
                    <div class="row">
                        <div class="form-group mb-2 col col-md-12">
                            <span class="display-7 mb-3 me-2">{{ $filters.capitalize($t('action.share')) }}: </span>
                            <a :href="'https://www.facebook.com/sharer/sharer.php?u=http://nongsanshop.com/selling-field/chi-tiet/'+ProductDetail.id" type="button" class="btn btn-success me-2 btn-sm" target="_blank"><i class="fab fa-facebook"></i></a>
                            <a :href="'https://twitter.com/intent/tweet?text='+ProductDetail.fm_field.name+'&url=http://nongsanshop.com/selling-field/chi-tiet/'+ProductDetail.id+'&hashtags='+ProductDetail.fm_field.name" type="button" class="btn btn-info me-2 btn-sm" target="_blank"><i class="fab fa-twitter"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row g-4">
                <div class="d-flex border-top">
                    <small class="w-100 text-center border-end py-2">
                        <a class="btn btn-primary rounded-pill py-2 px-3 btn-sm mt-3" :href="'/manager/field-buy-offer/create/'+ProductDetail.id">
                            <i class="fa fa-calendar-check text-white me-2"></i>{{ $filters.capitalize($t('action.send-price-offer')) }}
                        </a>
                    </small>
                </div>
            </div>
            <div class="row g-4" v-if="ProductDetail.fm_field.fm_my_objects != null">
                <div class="d-flex border-top">
                    <h4 class="display-7 mb-3 pt-3">{{ $filters.capitalize($t('selling-field.field-objects')) }}</h4>
                </div>
                <div v-for="(prod, index) in ProductDetail.fm_field.fm_my_objects" class="col-xl-2 col-lg-2 col-md-6 col-xs-6 col-sm-6 wow fadeInUp" data-wow-delay="0.1s" v-bind:key="index">
                    <object-item :obj="prod" />
                </div>
            </div>
            <div class="row g-0 gx-5 align-items-end">
                <div class="col-lg-6">
                    <div class="section-header text-start mb-5 wow fadeInUp" data-wow-delay="0.1s" style="max-width: 500px;">
                        <h4 class="display-7 mb-3">{{ $filters.capitalize($t('selling-field.related-fields')) }}</h4>
                    </div>
                </div>
            </div>
            <div class="row g-4">
                <div v-for="(prod, index) in products" class="col-xl-4 col-lg-4 col-md-6 col-xs-12 col-sm-12 wow fadeInUp" data-wow-delay="0.1s" v-bind:key="index">
                    <product-item :prod="prod" />
                </div>
                <div class="col-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                    <a class="btn btn-primary rounded-pill py-2 px-3 btn-sm" href="/selling-field">{{ $filters.capitalize($t('action.more-fields')) }}</a>
                </div>
            </div>
        </div>
    </div>
    <!-- About End -->
</template>
<script>
import { detail, list, listByCultivar } from '@/api/selling_field';
import { api_backend } from '@/constants/configs';
import ProductItem from './components/ProductItem.vue'
import ObjectItem from './components/ObjectItem.vue'
// import BuyerFeatures from '../components/BuyerFeatures.vue'
import NavbarIndex from '@/components/NavbarIndex.vue'
export default {
    name: "ProductDetail",
    components: {
        ProductItem,
        ObjectItem,
        NavbarIndex
    },
    data() {
        return {
            ProductDetail: {
                fm_field: {},
                selling_price: 0,
                deposit: 0,
                id: 0,
                feature_image: null,
                fm_my_objects: []
            },
            products: [],
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend,
            keywords: ''
        }
    },
    async created(){
        let res = await detail(this.$route.params.id);
        if (res != null && res.status == 200 && res.data != null ){
            console.log(res.data);
            this.ProductDetail = res.data;
            document.title = this.ProductDetail.fm_field.name;
            const descEl = document.querySelector('head meta[name="description"]');
            descEl.setAttribute('content', this.ProductDetail.fm_field.name);
            const keywordscEl = document.querySelector('head meta[name="keywords"]');
            keywordscEl.setAttribute('content', this.ProductDetail.fm_field.name);
            // set facebook feature
            const titleElFacebook = document.querySelector('head meta[property="og:title"]');
            if (titleElFacebook != null) {
                titleElFacebook.setAttribute('content', this.ProductDetail.fm_field.name);
            }
            const descElFacebook = document.querySelector('head meta[property="og:description"]');
            if (descElFacebook != null) {
                descElFacebook.setAttribute('content', this.ProductDetail.fm_field.name);
            }
            const urlElFacebook = document.querySelector('head meta[property="og:url"]');
            if (urlElFacebook != null) {
                urlElFacebook.setAttribute('content', this.host_url+"selling-field/chi-tiet/"+this.ProductDetail.id);
            }
            const imageElFacebook = document.querySelector('head meta[property="og:image"]');
            if (imageElFacebook != null && this.ProductDetail.feature_image != null) {
                imageElFacebook.setAttribute('content', this.host_url + this.ProductDetail.feature_image.replace('public', this.backend+'/storage'));
            }
            // set twitter feature
            const titleElTwitter= document.querySelector('head meta[name="twitter:title"]');
            if (titleElTwitter != null) {
                titleElTwitter.setAttribute('content', this.ProductDetail.fm_field.name);
            }
            const descElTwitter = document.querySelector('head meta[name="twitter:description"]');
            if (descElTwitter != null) {
                descElTwitter.setAttribute('content', this.ProductDetail.meta_description);
            }
            const imageElTwitter = document.querySelector('head meta[name="twitter:image"]');
            if (imageElTwitter != null && this.ProductDetail.feature_image != null) {
                imageElTwitter.setAttribute('content', this.host_url + this.ProductDetail.feature_image);
            }
            let resRelated = await listByCultivar('page=1&pagesize=6', this.ProductDetail.cultivar_id);
            if (resRelated != null && resRelated.status == 200 && resRelated.data != null && resRelated.data.data != null && resRelated.data.data.length > 0 ){
                this.products = resRelated.data.data;
                console.log(resRelated);
            } else {
                let resList = await list('page=1&pagesize=4');
                if (resList != null && resList.status == 200 && resList.data != null ){
                    this.products = resList.data.data;
                }
            }
        }
    },
    methods: {
        changeKeyword(e){
            this.keywords = e.target.value;
        },
        onSearch(){
            this.$router.push({ path: '/selling-field', query: { keywords: this.keywords } });
        }
    }
}
</script>